import { Component, OnInit } from "@angular/core";
import { BillingUser } from "src/app/models/billingUser";
import { Invoice } from "src/app/models/invoice";
import { PaymentPlan } from "src/app/models/paymentPlan";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-billing-info",
  templateUrl: "./billing-info.component.html",
})
export class BillingInfoComponent implements OnInit {
  billingUser: BillingUser = new BillingUser();
  invoices: Invoice[] = [];
  paymentPlan!: PaymentPlan;
  constructor(
    private apiService: ApiService,
    public popupService: PopupService,
    private stateService: StateService
  ) { }

  async ngOnInit(): Promise<void> {
    // Promise.all([this.LoadBillingInfo(), this.LoadAllPayments()]);
    // this.LoadAllPayments();
    this.LoadCurrentPlan();
  }

  // public async LoadBillingInfo() {
  //   const result = await this.apiService.GetBillingInfo(
  //     this.stateService.firebaseUser
  //   );

  //   if (result?.errorMessage) {
  //     this.popupService.Notify("Error loading billing info data.");
  //   }

  //   if (result?.data) {
  //     this.billingUser = result.data;
  //   }
  // }

  // public async UpdateBillingInfo() {
  //   const result = await this.apiService.UpdateBillingInfo(
  //     this.stateService.firebaseUser,
  //     this.billingUser
  //   );

  //   if (result?.errorMessage)
  //     this.popupService.Notify("Error updating billing info data.");

  //   if (result?.data)
  //     this.popupService.Notify("Successfuly updated billing info.");
  // }

  public async LoadCurrentPlan() {
    const result = await this.apiService.GetCurrentPlan();

    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);

    if (result?.data) this.paymentPlan = result?.data;
  }

  public async LoadAllPayments() {
    const result = await this.apiService.GetAllPayments(
      this.stateService.firebaseUser
    );

    if (result?.errorMessage)
      this.popupService.Notify("Error loading billing invoices.");

    if (result?.data) this.invoices = result.data.payments;
  }

  public PreviewSelectedInvoice(inv: Invoice) {
    window.open(inv.receiptUrl, "_blank");
  }

  UpdateBillingInfo() {
    window.open(this.paymentPlan.updateUrl, "_blank");
  }
}
