import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  NgModule,
} from "@angular/core";
import {
  BrowserModule,
  REMOVE_STYLES_ON_COMPONENT_DESTROY,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app.routing-module";
import { MatDialogModule } from "@angular/material/dialog";

import { HomeComponent } from "./pages/home/home.component";
import { NavbarComponent } from "./components/navigation/navbar/navbar.component";
import { SearchResultsComponent } from "./pages/search-results/search-results.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from "ngx-pagination";
import { EntityInfoComponent } from "./pages/entity-info/entity-info.component";
import { DataLabelDirective } from "./components/directives/data-label.directive";
import { InViewDirective } from "./components/directives/in-view.directive";
import { CopyClipboardTextDirective } from "./components/directives/copy-clipboard-text.directive";
import { LoginComponent } from "./components/modals/login/login.component";
import { InputFocusDirective } from "./components/directives/input-focus.directive";
import { ValueFormatPipe } from "./components/pipes/value-format.pipe";
import { DateFormatPipe } from "./components/pipes/date-format.pipe";
import { DurationFormatPipe } from "./components/pipes/duration-format.pipe";
import { MatchingLetterPipe } from "./components/pipes/matching-letter.pipe";
import { VideoDetailComponent } from "./components/modals/video-detail/video-detail.component";
import { SafeUrlPipe } from "./components/pipes/safe-url.pipe";
import { FirebaseModule } from "./services/firebase/firebase.module";
import { ResetPasswordComponent } from "./components/modals/reset-password/reset-password.component";
import { RegisterComponent } from "./components/modals/register/register.component";
import { PremiumSubscriptionComponent } from "./components/modals/premium-subscription/premium-subscription.component";
import { appInit } from "./utils/app-init";
import { LineChartComponent } from "./components/charts/line-chart/line-chart.component";
import { FlagComponent } from "./components/flag/flag.component";
import { QuestionModalComponent } from "./components/modals/question-modal/question-modal.component";
import { TokenInterceptor } from "./utils/interceptors/token.interceptor";
import { SessionKeyInterceptor } from "./utils/interceptors/session-key.interceptor";
import { SearchSuggestionsComponent } from "./components/search-suggestions/search-suggestions.component";
import { InAppHomeComponent } from "./pages/in-app-home/in-app-home.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { InAppSidebarComponent } from "./components/navigation/in-app-sidebar/in-app-sidebar.component";
import { InAppNavbarComponent } from "./components/navigation/in-app-navbar/in-app-navbar.component";
import { SettingsComponent } from "./pages/settings/settings/settings.component";
import { AccountInfoComponent } from "./pages/settings/account-info/account-info.component";
import { SubscriptionPlanComponent } from "./pages/settings/subscription-plan/subscription-plan.component";
import { BillingInfoComponent } from "./pages/settings/billing-info/billing-info.component";
import { NewslettersInfoComponent } from "./pages/settings/newsletters-info/newsletters-info.component";
import { CountryPickerComponent } from "./components/select-dropdowns/country-picker/country-picker.component";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMenuModule } from "@angular/material/menu";
import { MatChipsModule } from "@angular/material/chips";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatRadioModule } from "@angular/material/radio";
import { TrainingsComponent } from "./pages/premium-content/trainings/trainings.component";
import { RankingsComponent } from "./pages/rankings/rankings.component";
import { CategorySelectComponent } from "./components/select-dropdowns/category-select/category-select.component";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { PreviewComponent } from "./components/modals/preview/preview.component";
import { PreviewBrandInfoComponent } from "./pages/entity-info/preview-brand-info/preview-brand-info.component";
import { PreviewCompanyInfoComponent } from "./pages/entity-info/preview-company-info/preview-company-info.component";
import { NonVideoAdsComponent } from "./components/non-video-ads/non-video-ads.component";
import { VideosListComponent } from "./components/tables/videos-list/videos-list.component";
import { DoughnutChartComponent } from "./components/charts/doughnut-chart/doughnut-chart.component";
import { MultiLineChartComponent } from "./components/charts/multi-line-chart/multi-line-chart.component";
import { CompaniesListComponent } from "./components/tables/companies-list/companies-list.component";
import { BrandsListComponent } from "./components/tables/brands-list/brands-list.component";

import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { MatSliderModule } from "@angular/material/slider";
import { SwipesComponent } from "./pages/swipes/swipes.component";
import { ToNearestPipe } from "./components/pipes/to-nearest.pipe";
import { AdsPerCountryComponent } from "./pages/entity-info/page-components/ads-per-country/ads-per-country.component";
import { CompanyInfoComponent } from "./pages/entity-info/page-components/company-info/company-info.component";
import { EntityStatComponent } from "./pages/entity-info/page-components/entity-stat/entity-stat.component";
import { VideoSwipesComponent } from "./pages/swipes/page-components/video-swipes/video-swipes.component";
import { BrandsRankingListComponent } from "./components/tables/brands-ranking-list/brands-ranking-list.component";
import { CompaniesRankingListComponent } from "./components/tables/companies-ranking-list/companies-ranking-list.component";
import { RatingChangeComponent } from "./pages/entity-info/page-components/rating-change/rating-change.component";
import { AbsPipe } from "./components/pipes/abs.pipe";
import { ModuleSelectComponent } from "./components/select-dropdowns/module-select/module-select.component";
import { AffiliateAdsComponent } from "./pages/affiliate-module/affiliate-ads/affiliate-ads.component";
import { AffiliateOffersComponent } from "./pages/affiliate-module/affiliate-offers/affiliate-offers.component";
import { AffiliateCompaniesComponent } from "./pages/affiliate-module/affiliate-companies/affiliate-companies.component";
import { AffiliateLandersComponent } from "./pages/affiliate-module/affiliate-landers/affiliate-landers.component";
import { VideosAffiliateListComponent } from "./components/tables/videos-affiliate-list/videos-affiliate-list.component";
import { OffersListComponent } from "./components/tables/offers-list/offers-list.component";
import { LandersListComponent } from "./components/tables/landers-list/landers-list.component";
import { EntitySelectComponent } from "./components/select-dropdowns/entity-select/entity-select.component";
import { NetworkSelectComponent } from "./components/select-dropdowns/network-select/network-select.component";
import { IntervalSelectComponent } from "./components/select-dropdowns/interval-select/interval-select.component";
import { BarChartComponent } from "./components/charts/bar-chart/bar-chart.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SkeletonLoaderComponent } from "./components/skeleton-loader/skeleton-loader.component";
import { RangeSelectComponent } from "./components/select-dropdowns/range-select/range-select.component";
import { OfferInfoComponent } from "./pages/entity-info/page-components/offer-info/offer-info.component";
import { FilterChipsComponent } from "./components/filter-chips/filter-chips.component";
import { SelectTrainingCategoryComponent } from "./components/select-dropdowns/select-training-category/select-training-category.component";
import { TrainingContentComponent } from "./pages/premium-content/training-content/training-content.component";
import { LanguageSelectComponent } from "./components/select-dropdowns/language-select/language-select.component";
import { DurationSelectComponent } from "./components/select-dropdowns/duration-select/duration-select.component";
import { SelectPayingPlanComponent } from "./components/modals/select-paying-plan/select-paying-plan.component";
import { PricingComponent } from "./pages/nav-links/pricing/pricing.component";
import { AboutComponent } from "./pages/nav-links/about/about.component";
import { ContactComponent } from "./pages/nav-links/contact/contact.component";
import { FooterComponent } from "./components/navigation/footer/footer.component";
import { AdsComponent } from "./pages/marketer-module/ads/ads.component";
import { CompaniesComponent } from "./pages/marketer-module/companies/companies.component";
import { BrandsComponent } from "./pages/marketer-module/brands/brands.component";
import { TermsOfServiceComponent } from "./pages/nav-links/terms-of-service/terms-of-service.component";
import { VideoStatusSelectComponent } from "./components/select-dropdowns/video-status-select/video-status-select.component";
import { PrivacyPolicyComponent } from "./pages/nav-links/privacy-policy/privacy-policy.component";
import { YesNoComponent } from "./components/modals/yes-no/yes-no.component";
import { SoftwareSelectComponent } from "./components/select-dropdowns/software-select/software-select.component";
import { AdInfoComponent } from "./pages/ad-info/ad-info.component";
import { CreativeComponent } from "./pages/premium-content/creative/creative.component";
import { TawkComponent } from "./components/tawk/tawk.component";
import { WelcomeComponent } from "./components/modals/welcome/welcome.component";
import { StaticDataService } from "./services/shared/static.data.service";
import { SwipeBoardSelectComponent } from "./components/select-dropdowns/swipe-board-select/swipe-board-select.component";
import { ChooseBoardsComponent } from "./components/menus/choose-boards/choose-boards.component";
import { EditBoardComponent } from "./components/menus/edit-board/edit-board.component";
import { UpsertBoardComponent } from "./components/menus/upsert-board/upsert-board.component";
import { BasicInfoComponent } from "./components/modals/signup-sequence/basic-info/basic-info.component";
import { SwipeButtonComponent } from "./components/buttons/swipe-button/swipe-button.component";
import { ShareButtonComponent } from "./components/buttons/share-button/share-button.component";
import { ShareSocialComponent } from "./components/menus/share-social/share-social.component";
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";
import { ShareIconsModule } from "ngx-sharebuttons/icons";
import { CategoryTreeSelectComponent } from "./components/select-dropdowns/category-tree-select/category-tree-select.component";
import { PickAnswerQuestionComponent } from "./components/modals/signup-sequence/pick-answer-question/pick-answer-question.component";
import { SpendSelectComponent } from "./components/select-dropdowns/spend-select/spend-select.component";
import { VerifyCheckComponent } from "./components/modals/verify-check/verify-check.component";
import { BookStrategySessionComponent } from "./components/banners/book-strategy-session/book-strategy-session.component";
import { NavigatorSessionComponent } from "./components/banners/navigator-session/navigator-session.component";
import { TreeModule } from "primeng/tree";
import { TreeSelectModule } from "primeng/treeselect";
import { TreeTableModule } from "primeng/treetable";
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { OfferSelectComponent } from "./components/select-dropdowns/offer-select/offer-select.component";
import { LockUpgradeIconComponent } from "./components/lock-upgrade-icon/lock-upgrade-icon.component";
import { AffiliateNetworksComponent } from "./pages/affiliate-module/affiliate-networks/affiliate-networks.component";
import { TutorialComponent } from "./components/modals/tutorial/tutorial.component";
import { YtPlayerControlsComponent } from "./components/yt-player-controls/yt-player-controls.component";
import { FeedComponent } from "./components/feed/feed.component";
import { FeedItemComponent } from "./components/feed/feed-item/feed-item.component";
import { LikeDislikeComponent } from "./components/feed/like-dislike/like-dislike.component";
import { PlanRadioComponent } from './components/buttons/plan-radio/plan-radio.component';
import { TrialSubscriptionComponent } from './components/modals/trial-subscription/trial-subscription.component';
import { PopoverTextComponent } from './components/popover-text/popover-text.component';


export function init(injector: Injector) {
  return () => {
    return appInit(injector);
  };
}

export function initializeApp(startupService: StaticDataService): () => void {
  return () => { };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    SearchResultsComponent,
    EntityInfoComponent,
    DataLabelDirective,
    InViewDirective,
    CopyClipboardTextDirective,
    LoginComponent,
    InputFocusDirective,
    ValueFormatPipe,
    DateFormatPipe,
    DurationFormatPipe,
    MatchingLetterPipe,
    VideoDetailComponent,
    SafeUrlPipe,
    ResetPasswordComponent,
    RegisterComponent,
    PremiumSubscriptionComponent,
    LineChartComponent,
    FlagComponent,
    QuestionModalComponent,
    SearchSuggestionsComponent,
    InAppHomeComponent,
    DashboardComponent,
    InAppSidebarComponent,
    InAppNavbarComponent,
    SettingsComponent,
    AccountInfoComponent,
    SubscriptionPlanComponent,
    BillingInfoComponent,
    NewslettersInfoComponent,
    CountryPickerComponent,
    TrainingsComponent,
    RankingsComponent,
    CategorySelectComponent,
    PaginatorComponent,
    PreviewComponent,
    PreviewBrandInfoComponent,
    PreviewCompanyInfoComponent,
    NonVideoAdsComponent,
    VideosListComponent,
    DoughnutChartComponent,
    MultiLineChartComponent,
    CompaniesListComponent,
    BrandsListComponent,
    SwipesComponent,
    ToNearestPipe,
    AdsPerCountryComponent,
    CompanyInfoComponent,
    EntityStatComponent,
    VideoSwipesComponent,
    BrandsRankingListComponent,
    CompaniesRankingListComponent,
    RatingChangeComponent,
    AbsPipe,
    ModuleSelectComponent,
    AffiliateAdsComponent,
    AffiliateOffersComponent,
    AffiliateCompaniesComponent,
    AffiliateLandersComponent,
    VideosAffiliateListComponent,
    OffersListComponent,
    LandersListComponent,
    EntitySelectComponent,
    NetworkSelectComponent,
    IntervalSelectComponent,
    BarChartComponent,
    SkeletonLoaderComponent,
    RangeSelectComponent,
    OfferInfoComponent,
    FilterChipsComponent,
    SelectTrainingCategoryComponent,
    TrainingContentComponent,
    LanguageSelectComponent,
    DurationSelectComponent,
    SelectPayingPlanComponent,
    PricingComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    AdsComponent,
    CompaniesComponent,
    BrandsComponent,
    TermsOfServiceComponent,
    VideoStatusSelectComponent,
    PrivacyPolicyComponent,
    YesNoComponent,
    SoftwareSelectComponent,
    AdInfoComponent,
    CreativeComponent,
    TawkComponent,
    WelcomeComponent,
    SwipeBoardSelectComponent,
    ChooseBoardsComponent,
    EditBoardComponent,
    UpsertBoardComponent,
    SwipeButtonComponent,
    ShareButtonComponent,
    ShareSocialComponent,
    CategoryTreeSelectComponent,
    BasicInfoComponent,
    PickAnswerQuestionComponent,
    SpendSelectComponent,
    VerifyCheckComponent,
    BookStrategySessionComponent,
    NavigatorSessionComponent,
    OfferSelectComponent,
    LockUpgradeIconComponent,
    AffiliateNetworksComponent,
    TutorialComponent,
    YtPlayerControlsComponent,
    FeedComponent,
    FeedItemComponent,
    LikeDislikeComponent,
    PlanRadioComponent,
    TrialSubscriptionComponent,
    PopoverTextComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    MatFormFieldModule,
    MatCheckboxModule,
    DropdownModule,
    MultiSelectModule,
    MatSliderModule,
    MatChipsModule,
    MatCardModule,
    MatListModule,
    MatRadioModule,
    MatMenuModule,
    NgxSkeletonLoaderModule,
    ShareButtonsModule,
    ShareIconsModule,
    TreeModule,
    TreeSelectModule,
    TreeTableModule,
    OverlayPanelModule,
    FirebaseModule.forRoot(),
  ],
  providers: [
    StaticDataService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [StaticDataService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [Injector],
      multi: true,
    },
    {
      provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
      useValue: false,
    },
    // { provide: MatSelectCountryLangToken, useValue: 'en' }, // Replace 'en' with the desired language code
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionKeyInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
