import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { StateService } from "src/app/services/shared/state.service";

@Injectable({
  providedIn: "root",
})
export class IsLoggedInGuard {
  constructor(
    private stateService: StateService,
    public angularFireAuth: AngularFireAuth,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.stateService.user$.pipe(
      map((user) => {
        if (user) {
          // @ts-ignore
          if (window.profitwell) {
            // @ts-ignore
            window.profitwell("start", {
              user_email: user.email,
            });
          }
          return true;
        }

        this.stateService.redirectUrl = state.url;
        if (state.url.includes("register")) {
          this.stateService.openRegisterModal = true;
          this.stateService.redirectUrl = null;
        } else if (state.url.includes("subscribe")) {
          this.stateService.openSubscriptionModal = true;
          this.stateService.redirectUrl = "dashboard";
        }
        return this.router.createUrlTree(["/home"]);
      })
    );
  }
}
