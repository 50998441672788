import { Component, Input, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-popover-text',
  templateUrl: './popover-text.component.html',
})
export class PopoverTextComponent {
  @ViewChild('popover') popover!: OverlayPanel;
  @Input() title: string = '';
  @Input() text: string = '';

  showPopover(event: MouseEvent) {
    event.stopPropagation();
    this.popover.toggle(event);
  }
}
