import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AffiliateNetwork } from "src/app/models/affiliateNetwork";
import { Category } from "src/app/models/category";
import { Country } from "src/app/models/country";
import { Language } from "src/app/models/language";
import { ApiService } from "../api/api.service";
import { Duration } from "src/app/models/duration";
import { Software } from "src/app/models/software";
import { CategorizedTrainings } from "src/app/models/categorizedTrainings";
import { Training } from "src/app/models/training";
import { AffiliateOffer } from "src/app/models/affiliateOffer";
import { StateService } from "./state.service";
import { GetPaymentPlans } from "src/app/models/getPaymentPlans";
import { ReplaySubject, Subject, shareReplay } from "rxjs";

const categorizedTraingings = [
  {
    description: "How to use VidTao",
    trainings: [
      {
        description: "Welcome video",
        name: "Welcome video",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/welcome.png",
        id: 17,
        videos: [
          {
            name: "Welcome video",
            url: "https://player.vimeo.com/video/885168000?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description: "How the search works",
        name: "How the search works",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/how-search-works.png",
        id: 18,
        videos: [
          {
            name: "How the search works",
            url: "https://player.vimeo.com/video/885167686?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description: "Affiliate module",
        name: "Affiliate module",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/affiliate-module.png",
        id: 20,
        videos: [
          {
            name: "Affiliate module",
            url: "https://player.vimeo.com/video/957829249?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description:
          "How to interpret the data on VidTao to find the most relevant competitor’s ads",
        name: "How to interpret the data on VidTao to find the most relevant competitor’s ads",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/how-to-interept.png",
        id: 21,
        videos: [
          {
            name: "How to interpret the data on VidTao to find the most relevant competitor’s ads",
            url: "https://player.vimeo.com/video/925368537?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description: "Evaluate the ad performance of a brand with these charts",
        name: "Evaluate the ad performance of a brand with these charts",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/evaluate-ad-performance.png",
        id: 22,
        videos: [
          {
            name: "Evaluate the ad performance of a brand with these charts",
            url: "https://player.vimeo.com/video/925371168?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description:
          "Unlock hidden profits on YouTube by expanding your search for ad inspiration",
        name: "Unlock hidden profits on YouTube by expanding your search for ad inspiration",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/unlock-hidden-profits.png",
        id: 23,
        videos: [
          {
            name: "Unlock hidden profits on YouTube by expanding your search for ad inspiration",
            url: "https://player.vimeo.com/video/925372055?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description:
          "How to know if an ad is current and relevant for your ad inspiration",
        name: "How to know if an ad is current and relevant for your ad inspiration",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/relevant-ad.png",
        id: 24,
        videos: [
          {
            name: "How to know if an ad is current and relevant for your ad inspiration",
            url: "https://player.vimeo.com/video/933628230?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description: "How to find lead generation offers and ads on YouTube",
        name: "How to find lead generation offers and ads on YouTube",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/find-lead-offers.png",
        id: 25,
        videos: [
          {
            name: "How to find lead generation offers and ads on YouTube",
            url: "https://player.vimeo.com/video/933641163?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description:
          "Analysis of a Winning YouTube Advertising Strategy Based on a Lead Generation Offer",
        name: "Analysis of a Winning YouTube Advertising Strategy Based on a Lead Generation Offer",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/winning-yt-ads-strategy.png",
        id: 26,
        videos: [
          {
            name: "Analysis of a Winning YouTube Advertising Strategy Based on a Lead Generation Offer",
            url: "https://player.vimeo.com/video/925481516?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description:
          "Lead Copywriter’s Formula for Creating Winning YouTube Ads",
        name: "Lead Copywriter’s Formula for Creating Winning YouTube Ads",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/formula.png",
        id: 27,
        videos: [
          {
            name: "Lead Copywriter’s Formula for Creating Winning YouTube Ads",
            url: "https://player.vimeo.com/video/938205802?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description: "Find e-commerce brands and ads on YouTube using VidTao",
        name: "Find e-commerce brands and ads on YouTube using VidTao",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/find-e-comm.png",
        id: 28,
        videos: [
          {
            name: "Find e-commerce brands and ads on YouTube using VidTao",
            url: "https://player.vimeo.com/video/947337398?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description: "Discover the best sales funnels for YouTube",
        name: "Discover the best sales funnels for YouTube",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/best-funnels.png",
        id: 29,
        videos: [
          {
            name: "Discover the best sales funnels for YouTube",
            url: "https://player.vimeo.com/video/962085075?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
      {
        description:
          "VidTao Tutorial Advanced search methods and filters to find direct response ads, brands, and companies",
        name: "VidTao Tutorial Advanced search methods and filters to find direct response ads, brands, and companies",
        active: true,
        category: "welcome-videos",
        image: "assets/icons/advanced-search.png",
        id: 30,
        videos: [
          {
            name: "VidTao Tutorial Advanced search methods and filters to find direct response ads, brands, and companies",
            url: "https://player.vimeo.com/video/966562043?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
      },
    ],
  },
  {
    description: "Youtube advertising",
    trainings: [
      {
        description: "",
        videos: [
          {
            name: "Phase 1: Setup",
            url: "https://player.vimeo.com/video/765208584?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        image:
          "https://i.vimeocdn.com/video/1536521314-4dcd65156fc9964bb737ec63c47a7d656655e4258a31cf396fb8e1905ef258ec-d?mw=500&mh=500&q=70",
        name: "How To Set Google Ads Tracking",
        id: 14,
        category: "youtubeAds",
        slug: "",
      },
      {
        description: "",
        videos: [
          {
            name: "Phase 1",
            url: "https://player.vimeo.com/video/759415008?h=3c36e7690a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
          {
            name: "Phase 2",
            url: "https://player.vimeo.com/video/759414718?h=64007c1a68&badge=0&autopause=0&player_id=0&app_id=58479/embed",
          },
          {
            name: "Phase 3",
            url: "https://player.vimeo.com/video/759413771?h=2837415075&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
          {
            name: "Phase 4",
            url: "https://player.vimeo.com/video/759413299?h=dbd385b155&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
          {
            name: "Phase 5",
            url: "https://player.vimeo.com/video/759419117?h=2eed43abe4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        image:
          "https://i.vimeocdn.com/video/1525302483-cec1dfc6bf8db2e83ede57abd9fb41ab66742cd4bb2355689f0d5cf658e164b2-d?mw=500&mh=500&q=70",
        name: "Inceptly Video Creative System",
        id: 15,
        category: "youtubeAds",
        slug: "",
      },
      {
        description: "",
        videos: [
          {
            name: "1: Intro",
            url: "https://player.vimeo.com/video/758416660?h=98684a6b1b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
          {
            name: "2: Research",
            url: "https://player.vimeo.com/video/758416692?h=4192d87c11&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
          {
            name: "3: Targeting",
            url: "https://player.vimeo.com/video/758416707?h=d0826bfae6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
          {
            name: "4: Campaign Setup & Launch",
            url: "https://player.vimeo.com/video/758416677?h=493139f0ac&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        image:
          "https://i.vimeocdn.com/video/1523371472-80d6d348a8f16d6a717078804751cc6c8912b2e21756e86776bc564c50611be2-d?mw=500&mh=500&q=70",
        name: "YouTube Ad Media Buying",
        id: 6,
        category: "youtubeAds",
        slug: "",
      },
      {
        image:
          "https://i.vimeocdn.com/video/1545394363-68bc9c14079dfde7b14d939cad5aaa0cebc48c709d7d4f2e26e8372d08c043b0-d?mw=500&mh=500&q=70",
        description: "",
        videos: [
          {
            name: "Creative Testing",
            url: "https://player.vimeo.com/video/769978802?h=f39c055f8a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        name: "YouTube Ad Creative Testing Methodology with Alex Simic (Creative Director, Inceptly)",
        id: 12,
        category: "youtubeAds",
        slug: "",
      },
      {
        active: true,
        videos: [
          {
            name: "Google Performance Max Training w/ Brat Vukovich (co-founder VidTao / Inceptly)",
            url: "https://player.vimeo.com/video/791123434?h=fb6c7caa81&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        image:
          "https://i.vimeocdn.com/video/1591805198-3b63e18848c08fe0bba3216d162521fa27e07f9f88c43db64de2d3f6d77fe324-d?mw=600&mh=400&q=70",
        description:
          '<p>Hope you enjoyed this training.<br /><br />Here are some usefull links.<br /><br />1. Mike Rhodes script for tracking pmax <a class="training-link" href="https://github.com/agencysavvy/pmax/commit/a24ffc2a4a98ef4aaadb49fa8ff954f685ee0849" target="_blank" rel="noopener">https://github.com/agencysavvy/pmax/commit/a24ffc2a4a98ef4aaadb49fa8ff954f685ee0849</a></p><p>2. Great Article by Store Growers on pmax <a class="training-link" href="https://www.storegrowers.com/performance-max-campaigns/" target="_blank" rel="noopener">https://www.storegrowers.com/performance-max-campaigns/</a></p><p>I really hope you enjoyed it, and see you next time.</p>',
        name: "Google Performance Max Training w/ Brat Vukovich (co-founder VidTao / Inceptly)",
        id: 8,
        category: "youtubeAds",
        slug: "",
      },
    ],
  },
  {
    description: "Media buying",
    trainings: [
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_1648202623_yung_chang_q_A_Shc5_SV_83_M_unsplash_20_1_169e8a3aaa/large_1648202623_yung_chang_q_A_Shc5_SV_83_M_unsplash_20_1_169e8a3aaa.jpg",
        name: "Should we turn off brand ads",
        category: "advertising",
        slug: "should-we-turn-off-brand-ads",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/template-dogshed.png",
        name: "What other bad keywords are we matching?",
        category: "advertising",
        slug: "what-other-bad-keywords-are-we-matching",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1654195706-patrick-perkins-ETRPjvb0KM0-unsplash.jpg?alt=media&token=92301186-1f8f-4937-9de7-14ff3623e80b",
        name: "Let's keep our UTM tracking consistent",
        category: "advertising",
        slug: "lets-keep-our-utm-tracking-consistent",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1655220574-stillness-inmotion-Jh6aQX-25Uo-unsplash.jpg?alt=media&token=33c59626-364d-4748-a8ac-9606b5ec70ab",
        name: "Pause all the worst performing ads",
        category: "advertising",
        slug: "pause-all-the-worst-performing-ads",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1659480329-Screenshot%25202022-08-02%2520234518.png?alt=media&token=eca59348-91c2-4a84-8ca7-c1d7e3d4fe5f",
        name: "Who should we target?",
        category: "advertising",
        slug: "who-should-we-target",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1660909689-Screenshot%25202022-08-19%2520124802.png?alt=media&token=4a9797e5-117f-4516-8fc3-724ebf0c4d1d",
        name: "Can we get spending alerts in slack?",
        category: "advertising",
        slug: "can-we-get-spending-alerts-in-slack",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_Screenshot_2022_09_22_220103_4995a0c718/small_Screenshot_2022_09_22_220103_4995a0c718.png",
        name: "How to Plan a Retail Digital Media Campaign",
        category: "advertising",
        slug: "how-to-plan-a-retail-digital-media-campaign",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_decomp_chart_0f68f7122c/large_decomp_chart_0f68f7122c.png",
        name: "What's driving performance?",
        category: "advertising",
        slug: "what's-driving-performance",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_Screenshot_2022_10_21_120338_1138a4a427/small_Screenshot_2022_10_21_120338_1138a4a427.png",
        name: "We've got too many test ideas",
        category: "conversion",
        slug: "we've-got-too-many-test-ideas",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_Screenshot_2022_11_17_172552_af257339fb/small_Screenshot_2022_11_17_172552_af257339fb.png",
        name: "Compile a report by channel",
        category: "attribution",
        slug: "compile-a-report-by-channel",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_Screenshot_2022_12_01_142750_537f20092e/medium_Screenshot_2022_12_01_142750_537f20092e.png",
        name: "How should we set our advertising budget?",
        category: "advertising",
        slug: "how-should-we-set-our-advertising-budget",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_20221208_155618_947b7491cc/large_Screenshot_20221208_155618_947b7491cc.png",
        name: "It takes too long to optimize to that",
        category: "advertising",
        slug: "it-takes-too-long-to-optimize-to-that",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_Screenshot_20221214_112815_4998b0aaa9/medium_Screenshot_20221214_112815_4998b0aaa9.png",
        name: "How do we measure lead quality?",
        category: "advertising",
        slug: "how-do-we-measure-lead-quality",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_20221214_093939_2e58ce523e/large_Screenshot_20221214_093939_2e58ce523e.png",
        name: "What's our cost per qualified lead?",
        category: "advertising",
        slug: "what's-our-cost-per-qualified-lead",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_05_09_134212_2ce1c02cf2/large_Screenshot_2023_05_09_134212_2ce1c02cf2.png",
        name: "Calculating all the KPIs",
        category: "advertising",
        slug: "calculating-all-the-kpis",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_07_23_at_11_21_13_35f463f6f7/large_Screenshot_2023_07_23_at_11_21_13_35f463f6f7.png",
        name: "What am I willing to pay per click?",
        category: "advertising",
        slug: "what-am-i-willing-to-pay-per-click",
      },
    ],
  },
  {
    description: "Prompt engineering",
    trainings: [
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_ai_custom_illustrations_54c556af_48c4_4854_823b_45dc6beb70ab_f956598d64/large_ai_custom_illustrations_54c556af_48c4_4854_823b_45dc6beb70ab_f956598d64.mp4",
        name: "Make custom illustrations for our blog",
        category: "content",
        slug: "make-custom-illustrations-for-our-blog",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_1658847454_Screenshot_202022_07_26_20120107_ce91c8c202/large_1658847454_Screenshot_202022_07_26_20120107_ce91c8c202.png",
        name: "Can we summarize at scale?",
        category: "content",
        slug: "can-we-summarize-at-scale",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_Screenshot_2022_09_08_153939_95fe6d4e1b/small_Screenshot_2022_09_08_153939_95fe6d4e1b.png",
        name: "We need a new logo",
        category: "content",
        slug: "we-need-a-new-logo",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_textual_inversion_course_5bf1343a83/small_textual_inversion_course_5bf1343a83.png",
        name: "Can we train AI on our brand?",
        category: "advertising",
        slug: "can-we-train-ai-on-our-brand",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_Screenshot_2022_10_06_232201_5d841b68ed/small_Screenshot_2022_10_06_232201_5d841b68ed.png",
        name: "What's working in our creative?",
        category: "advertising",
        slug: "what's-working-in-our-creative",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_Screenshot_2022_10_11_125827_9e7c7fe351/small_Screenshot_2022_10_11_125827_9e7c7fe351.png",
        name: "What's working for videos in my industry?",
        category: "advertising",
        slug: "what's-working-for-videos-in-my-industry",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_Screenshot_2022_11_03_143148_8692fb9594/small_Screenshot_2022_11_03_143148_8692fb9594.png",
        name: "What do our customers think?",
        category: "virality",
        slug: "what-do-our-customers-think",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_Screenshot_2022_11_08_173414_b7cf3f8f59/small_Screenshot_2022_11_08_173414_b7cf3f8f59.png",
        name: "Make me 205 ads",
        category: "advertising",
        slug: "make-me-205-ads",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_DALL_E_2023_01_06_15_31_32_A_robot_writing_on_a_typewriter_d34586d156/large_DALL_E_2023_01_06_15_31_32_A_robot_writing_on_a_typewriter_d34586d156.png",
        name: "How can we scale our social media content?",
        category: "content",
        slug: "how-can-we-scale-our-social-media-content",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_Screenshot_20230112_130146_36735b1b6c/medium_Screenshot_20230112_130146_36735b1b6c.png",
        name: "Generating a long-tail keyword list",
        category: "content",
        slug: "generating-a-long-tail-keyword-list",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Untitled_1_f84e34b784/large_Untitled_1_f84e34b784.png",
        name: "What type of game do people want?",
        category: "conversion",
        slug: "what-type-of-game-do-people-want",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_Stock_0870f16bcc/medium_Stock_0870f16bcc.png",
        name: "Where can we get free stock photos?",
        category: "content",
        slug: "where-can-we-get-free-stock-photos",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_ticktock_c8d31267d3/large_ticktock_c8d31267d3.png",
        name: "Copying an artist without ripping them off",
        category: "content",
        slug: "copying-an-artist-without-ripping-them-off",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_elsa_35747d5d43/medium_elsa_35747d5d43.png",
        name: "Can we use AI to do product placement?",
        category: "advertising",
        slug: "can-we-use-ai-to-do-product-placement",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_vector_1dafa6f1b0/medium_vector_1dafa6f1b0.png",
        name: "Stop my AI hallucinating",
        category: "content",
        slug: "stop-my-ai-hallucinating",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_04_17_152000_7be5f1ce76/large_Screenshot_2023_04_17_152000_7be5f1ce76.png",
        name: "Is it possible to write longer stories without the AI hallucinating?",
        category: "content",
        slug: "is-it-possible-to-write-longer-stories-without-the-ai-hallucinating",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_Screenshot_2023_04_17_165036_89cf8b5527/medium_Screenshot_2023_04_17_165036_89cf8b5527.png",
        name: "Can we make our product shoot like Christopher Nolan's Batman?",
        category: "content",
        slug: "can-we-make-our-product-shoot-like-christopher-nolan's-batman",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_05_09_at_12_15_40_34669586af/large_Screenshot_2023_05_09_at_12_15_40_34669586af.png",
        name: "How can I understand the reAct pattern?",
        category: "advertising",
        slug: "how-can-i-understand-the-react-pattern",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_AI_Blog_Generation_7135914111/large_AI_Blog_Generation_7135914111.png",
        name: "AI Blog Post Creation",
        category: "content",
        slug: "ai-blog-post-creation",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Summarizing_Large_Documents_in_Lang_Chain_62099c429d/large_Summarizing_Large_Documents_in_Lang_Chain_62099c429d.png",
        name: "Summarizing Large Documents",
        category: "content",
        slug: "summarizing-large-documents",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_xyz_prompt_grids_f1ee93e3_1ed4_4eb9_9c97_e3104aec4bc9_f4e354c42e/large_xyz_prompt_grids_f1ee93e3_1ed4_4eb9_9c97_e3104aec4bc9_f4e354c42e.mp4",
        name: "How can we test the effect of all these parameters?",
        category: "content",
        slug: "how-can-we-test-the-effect-of-all-these-parameters",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_automatic_1111_94a7daf4_f7b4_457b_9aa7_e85caa630cbb_6a01b6bbb9/large_automatic_1111_94a7daf4_f7b4_457b_9aa7_e85caa630cbb_6a01b6bbb9.mp4",
        name: "How do I use Stable Diffusion locally without code?",
        category: "content",
        slug: "how-do-i-use-stable-diffusion-locally-without-code",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_openai_finetuning_11d54764_f693_4028_bd95_15853ffd1182_997dae578b/large_openai_finetuning_11d54764_f693_4028_bd95_15853ffd1182_997dae578b.mp4",
        name: "I want ChatGPT to write in my exact style.",
        category: "content",
        slug: "i-want-chatgpt-to-write-in-my-exact-style.",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_controlnet_1a3e82a6_80d8_46ff_aef2_21cdc3353f39_545fd28598/large_controlnet_1a3e82a6_80d8_46ff_aef2_21cdc3353f39_545fd28598.mp4",
        name: "I want something like this, but original",
        category: "advertising",
        slug: "i-want-something-like-this-but-original",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_prompt_5faa6be0_9a6f_4ed1_80b8_8992df8f4963_3a6a0bf774/large_prompt_5faa6be0_9a6f_4ed1_80b8_8992df8f4963_3a6a0bf774.mp4",
        name: "No more blindly prompting AI",
        category: "content",
        slug: "no-more-blindly-prompting-ai",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_segment_anything_inpainting_c4250fee_89b2_450a_8584_316f4f77152c_ea5b7522f4/large_segment_anything_inpainting_c4250fee_89b2_450a_8584_316f4f77152c_ea5b7522f4.mp4",
        name: "How do we cut out and replace parts of an image?",
        category: "advertising",
        slug: "how-do-we-cut-out-and-replace-parts-of-an-image",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_recursive_reprompting_and_revision_4555b108_87fd_44be_a594_f7ee9f1d1259_e6129fd767/large_recursive_reprompting_and_revision_4555b108_87fd_44be_a594_f7ee9f1d1259_e6129fd767.mp4",
        name: "I want to write a whole story with AI",
        category: "content",
        slug: "i-want-to-write-a-whole-story-with-ai",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_realistic_ai_models_98dc98c5_529d_49a7_be43_6a02d8d883f4_c38578f0b7/large_realistic_ai_models_98dc98c5_529d_49a7_be43_6a02d8d883f4_c38578f0b7.mp4",
        name: "I need a model that looks like a celebrity but isn't",
        category: "advertising",
        slug: "i-need-a-model-that-looks-like-a-celebrity-but-isn't",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_text_generation_webui_54802f93_900f_4978_978e_d46d352bda7a_2f94a2d8be/medium_text_generation_webui_54802f93_900f_4978_978e_d46d352bda7a_2f94a2d8be.mp4",
        name: "Exploring Open Source Models with Text Generation WebUI",
        category: "content",
        slug: "exploring-open-source-models-with-text-generation-webui",
      },
    ],
  },
  {
    description: "Tehnical SEO",
    trainings: [
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_keyword_clusters_a1fe990182/large_keyword_clusters_a1fe990182.jpeg",
        name: "How do we cluster keywords?",
        category: "content",
        slug: "how-do-we-cluster-keywords",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_DALL_E_2023_01_16_10_48_02_42bc36dd2c/large_DALL_E_2023_01_16_10_48_02_42bc36dd2c.png",
        name: "Can we improve our FAQ generation process?",
        category: "content",
        slug: "can-we-improve-our-faq-generation-process",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_02_19_at_13_07_30_aff0af0c63/large_Screenshot_2023_02_19_at_13_07_30_aff0af0c63.png",
        name: "How can we automatically add FAQ schema?",
        category: "content",
        slug: "how-can-we-automatically-add-faq-schema",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_03_04_at_15_00_31_bbcd86ae42/large_Screenshot_2023_03_04_at_15_00_31_bbcd86ae42.png",
        name: "Competitor Keyword Research - Ahrefs",
        category: "content",
        slug: "competitor-keyword-research-ahrefs",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_How_to_combine_1000_csv_files_in_python_2238d0797f/medium_How_to_combine_1000_csv_files_in_python_2238d0797f.png",
        name: "Combining 1000 csv files with Python",
        category: "content",
        slug: "combining-1000-csv-files-with-python",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_webscraping_in_beautiful_soup_4448a697a8/large_webscraping_in_beautiful_soup_4448a697a8.png",
        name: "How can I scrape data in Python?",
        category: "content",
        slug: "how-can-i-scrape-data-in-python",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_AI_Blog_Generation_7135914111/large_AI_Blog_Generation_7135914111.png",
        name: "AI Blog Post Creation",
        category: "content",
        slug: "ai-blog-post-creation",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Summarizing_Large_Documents_in_Lang_Chain_62099c429d/large_Summarizing_Large_Documents_in_Lang_Chain_62099c429d.png",
        name: "Summarizing Large Documents",
        category: "content",
        slug: "summarizing-large-documents",
      },
    ],
  },
  {
    description: "Marketing mix",
    trainings: [
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2Fmarkus-winkler-IrRbSND5EUc-unsplash.jpg?alt=media&token=d2de9421-94b5-426b-9277-f41c911ca3bc",
        name: "What's the impact of TV ads?",
        category: "attribution",
        slug: "whats-the-impact-of-tv-ads",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2Fkenrick-baksh-oG6DL5JZOO0-unsplash.jpg?alt=media&token=c54603b5-2b53-4290-b2d1-274072c4b813",
        name: "iOS14 Broke Our Tracking!",
        category: "attribution",
        slug: "ios14-broke-our-tracking",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_courses_campaign_creators_pype_C_Ea_Je_ZY_unsplash_e068576141/large_courses_campaign_creators_pype_C_Ea_Je_ZY_unsplash_e068576141.webp",
        name: "How do I build a marketing mix model?",
        category: "attribution",
        slug: "how-do-i-build-a-marketing-mix-model",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2Fm-b-m-ZzOa5G8hSPI-unsplash.jpg?alt=media&token=fd9fe42a-f57f-4bb1-a820-d68fd3c88033",
        name: "How much more can we spend?",
        category: "attribution",
        slug: "how-much-more-can-we-spend",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2Fphoto-1536521642388-441263f88a61.jfif?alt=media&token=1da6efaf-0758-455b-8af2-7045ac516eae",
        name: "Do we sell more when it's sunny?",
        category: "attribution",
        slug: "do-we-sell-more-when-its-sunny",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1652859764-fusion-medical-animation-rnr8D3FNUNY-unsplash.jpg?alt=media&token=354f1e0e-ada0-450e-8c9f-7aed42848177",
        name: "How do I control for COVID?",
        category: "attribution",
        slug: "how-do-i-control-for-covid",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2Fphoto-1471110338536-858caa3dbe45.jfif?alt=media&token=c5421916-c03f-497d-be27-2326a5473514",
        name: "Maybe it's just seasonality?",
        category: "attribution",
        slug: "maybe-its-just-seasonality",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2Flee-jeffs-g-Dd6b_rMFY-unsplash.jpg-1633621681?alt=media&token=50290f64-d8f2-48f7-91d1-499545d32a20",
        name: "Can we try Facebook Robyn?",
        category: "attribution",
        slug: "can-we-try-facebook-robyn",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1654676024-roman-synkevych-dc4smzAP_co-unsplash.jpg?alt=media&token=abf00fc3-8947-45e1-85e8-f47c39c96194",
        name: "Can we try Google LightweightMMM?",
        category: "attribution",
        slug: "can-we-try-google-lightweightmmm",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1658410271-Screenshot%25202022-07-21%2520143019.png?alt=media&token=355dc5b6-a1a1-46be-9dca-37ac79fa3623",
        name: "Can we try Uber Orbit?",
        category: "attribution",
        slug: "can-we-try-uber-orbit",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/But%20isn't%20everything%20interacting.png",
        name: "But isn't everything interacting?",
        category: "attribution",
        slug: "but-isnt-everything-interacting",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2Fmorgan-lane-BEF-7cpER3s-unsplash.jpg-1633038009?alt=media&token=ab8f39a3-f96f-462d-91a9-f3d2f93e4a37",
        name: "Do I have enough data?",
        category: "attribution",
        slug: "do-i-have-enough-data",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/GoolyBib%20ABT%20(2).png",
        name: "How can Facebook drive negative sales?",
        category: "attribution",
        slug: "how-can-facebook-drive-negative-sales",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F%241639141011-%7Bimage.name%7D?alt=media&token=db504b6c-bdc2-46b1-8710-af7b29b48745",
        name: "Why don't we try AI?",
        category: "attribution",
        slug: "why-dont-we-try-ai",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F%241638548594-%7Bimage.name%7D?alt=media&token=b2b7decb-287a-4b06-84c8-ab699a5a9da9",
        name: "Do ads behave differently during a sale?",
        category: "attribution",
        slug: "do-ads-behave-differently-during-a-sale",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2Fkolleen-gladden-ij5_qCBpIVY-unsplash%20(1).jpg?alt=media&token=b33cd2e5-93d1-42b4-a90c-b8d229f5998b",
        name: "What are my key performance drivers?",
        category: "attribution",
        slug: "what-are-my-key-performance-drivers",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2Fsharon-mccutcheon--8a5eJ1-mmQ-unsplash.jpg?alt=media&token=a9a2e1c8-4154-4323-a421-58516ff2b212",
        name: "Where should I allocate spend?",
        category: "attribution",
        slug: "where-should-i-allocate-spend",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F%241637265084-%7Bimage.name%7D?alt=media&token=ea0f2c78-4552-46a1-b3d8-2cea035f0274",
        name: "What's the lagged effect of our ads?",
        category: "attribution",
        slug: "whats-the-lagged-effect-of-our-ads",
      },
    ],
  },
  {
    description: "Other",
    trainings: [
      {
        image: "",
        description: "",
        videos: [
          {
            name: "",
            url: "https://player.vimeo.com/video/793310396?h=db381de617&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        name: "YouTube Ads Q&A: Breaking Updates - January 2023",
        id: 1,
        category: "youtubeAds",
      },
      {
        image: "",
        name: "Unlimited Hooks with Billy Broas - June 2023",
        description: "",
        videos: [
          {
            name: "Unlimited Hooks with Billy Broas - June 2023",
            url: "https://player.vimeo.com/video/837534784?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        id: 2,
        category: "youtubeAds",
      },
      {
        active: true,
        videos: [
          {
            name: "Creatives Bootcamp Episode 1",
            url: "https://player.vimeo.com/video/766368920?h=7a7f6032d4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
          {
            name: "Creatives Bootcamp Episode 2",
            url: "https://player.vimeo.com/video/767749901?h=2fcbc9c494&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        image:
          "https://i.vimeocdn.com/video/1544456749-bc26398ce204dcc2d142f0c1efe535d6f1b252a8627bbf3b50c0c6a3f939a3f0-d?mw=500&mh=500&q=70",
        description:
          '<p>Link to checklist: <a class="training-link" href="http://vidtao.com/ecommercechecklist2022" target="_blank" rel="noopener">http://vidtao.com/ecommercechecklist2022</a></p>',
        name: "eCommerce YouTube ads",
        id: 3,
        category: "youtubeAds",
      },
      {
        image: "",
        name: "YouTube Ad Compliance & Copy Live Training - February 2023",
        description: "",
        videos: [
          {
            name: "YouTube Ad Compliance & Copy Live Training - February 2023",
            url: "https://player.vimeo.com/video/802360978?h=9042a7448a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        id: 4,
        category: "youtubeAds",
      },
      {
        image: "",
        description: "",
        videos: [
          {
            name: "",
            url: "https://player.vimeo.com/video/795548264?h=61d7f49a9a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        name: "YouTube Targeting & Optimisations Tips & Tricks - February 2023",
        id: 5,
        category: "youtubeAds",
      },
      {
        description: "",
        videos: [
          {
            name: "Lesson 1",
            url: "https://player.vimeo.com/video/780002628?h=952c453af4",
          },
        ],
        active: true,
        image:
          "https://i.vimeocdn.com/video/1566036044-505146a0f7d43486c9154a969c814e8f497b680c08510b4d0b032172677098df-d?mw=500&mh=400&q=70",
        name: "YouTube Shorts Ads - December 2022 Update",
        id: 10,
        category: "youtubeAds",
      },
      {
        active: true,
        image:
          "https://i.vimeocdn.com/video/1538850975-69a30021eb1a29388f847cd9e8e87834ae4b646b904dd2c2301dda9fb4b413fd-d?mw=500&mh=400&q=70",
        videos: [
          {
            name: "Video22",
            url: "https://player.vimeo.com/video/766369369?h=60c6ea9591&badge=0&autopause=0&player_id=0&app_id=58479",
          },
        ],
        description:
          '<p>Get in touch with Alex here:</p><p>&nbsp;</p><p>Alex\'s eCommerce Scaling Secrets:</p><p><a class="training-link" href="https://www.ecommercescalingsecrets.com/bba-v1?sl=vidtaoapp" target="_blank" rel="noopener">https://www.ecommercescalingsecrets.com/bba-v1?sl=vidtaoapp</a></p><p>&nbsp;</p><p>Alex\'s Instagram:</p><p><a class="training-link" href="https://www.instagram.com/alexfedotofff/" target="_blank" rel="noopener">https://www.instagram.com/alexfedotofff/</a></p>',
        name: "Facebook Ad Scaling beyond $1M/mo with Alex Fedotoff",
        id: 7,
        category: "youtubeAds",
      },
      {
        image: "",
        description: "",
        videos: [
          {
            name: "VidTao Info Product Funnel Teardown Training Mar 2023",
            url: "https://player.vimeo.com/video/806367715?h=fefef305e6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        name: "VidTao Info Product Funnel Teardown Training - March 2023",
        id: 11,
        category: "youtubeAds",
      },
      {
        image: "",
        description: "",
        videos: [
          {
            name: "",
            url: "https://player.vimeo.com/video/797721577?h=3f054f2ae3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        name: "Direct Response Video Editing Laws for High Performance YouTube Ads - February 2023",
        id: 13,
        category: "youtubeAds",
      },
      {
        image: "",
        description: "",
        videos: [
          {
            name: "YouTube Ads Q&A: Your Questions answered, May, 2023",
            url: "https://player.vimeo.com/video/829368375?h=8f218d93e6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
          },
        ],
        active: true,
        name: "YouTube Ads Q&A: Your Questions Answered - May 2023",
        id: 16,
        category: "youtubeAds",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1649944413-ryan-spencer-Um9AkOiIDcU-unsplash.jpg?alt=media&token=c9203410-d0f1-433e-9a55-05adf78ad4b8",
        name: "What attributes should we talk about?",
        category: "content",
        slug: "what-attributes-should-we-talk-about",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_memetic_analysis_02c8525b_7b05_41b6_abfc_3386afc64f68_1aa0606445/large_memetic_analysis_02c8525b_7b05_41b6_abfc_3386afc64f68_1aa0606445.mp4",
        name: "How do I increase my chances of getting a guest post?",
        category: "content",
        slug: "how-do-i-increase-my-chances-of-getting-a-guest-post",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_05_09_102412_4ca742290a/large_Screenshot_2023_05_09_102412_4ca742290a.png",
        name: "Finding Numbers for Greek Letters",
        category: "attribution",
        slug: "finding-numbers-for-greek-letters",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_advanced_inpainting_0555d7b2_ea23_4e23_bb3d_eac8d1dd1224_8218785c6b/large_advanced_inpainting_0555d7b2_ea23_4e23_bb3d_eac8d1dd1224_8218785c6b.mp4",
        name: "I want something like Generative Fill from Photoshop, but I want it to work",
        category: "advertising",
        slug: "i-want-something-like-generative-fill-from-photoshop-but-i-want-it-to-work",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1657278745-Screenshot%25202022-07-08%2520121214.png?alt=media&token=2ba62ed7-6a79-474c-a05a-dd81637248dd",
        name: "Brief the next blog post",
        category: "content",
        slug: "brief-the-next-blog-post",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F%241638182240-%7Bimage.name%7D?alt=media&token=3205397c-5940-4f31-a431-4def7ad1cd5a",
        name: "Can we prove the ROI of Ads?",
        category: "attribution",
        slug: "can-we-prove-the-roi-of-ads",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/loan-transactions.png",
        name: "Are we retaining users?",
        category: "retention",
        slug: "are-we-retaining-users",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_1661442381_Screenshot_202022_08_25_20115351_4900edd322/small_1661442381_Screenshot_202022_08_25_20115351_4900edd322.png",
        name: "Can we match up our landing page copy?",
        category: "conversion",
        slug: "can-we-match-up-our-landing-page-copy",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_20230116_170846_ca3f5913a9/large_Screenshot_20230116_170846_ca3f5913a9.png",
        name: "Can we run tests without Google Optimize?",
        category: "conversion",
        slug: "can-we-run-tests-without-google-optimize",
      },
      {
        image: "https://storage.googleapis.com/strapi_cms_assets/NeverGrad.png",
        name: "Let's try 10,000 models",
        category: "attribution",
        slug: "lets-try-10000-models",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2Fanastase-maragos-X4zx5Vc_LZU-unsplash%20(1).jpg?alt=media&token=63bda0c8-5cef-48f8-a0a6-3859206a5a4a",
        name: "Can we trust this model?",
        category: "attribution",
        slug: "can-we-trust-this-model",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1660099021-ga4-gotchas.jpg?alt=media&token=d3346652-cf22-4c78-bd2e-9f0da91c1bdb",
        name: "We need to set up GA4",
        category: "attribution",
        slug: "we-need-to-set-up-ga4",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_testing_opensource_models_c7c71074_4a25_40c8_84e0_78d451a3fa52_eb84fa743b/large_testing_opensource_models_c7c71074_4a25_40c8_84e0_78d451a3fa52_eb84fa743b.mp4",
        name: "Can we run this prompt locally instead of using OpenAI?",
        category: "content",
        slug: "can-we-run-this-prompt-locally-instead-of-using-openai",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F%241636708785-%7Bimage.name%7D?alt=media&token=9f78caf9-1e08-497c-ab2a-2af1d73ea0d5",
        name: "Can we stop this test early?",
        category: "conversion",
        slug: "can-we-stop-this-test-early",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_this_scene_but_different_0f9d6425_f9b8_422c_bcd5_73076f9a2f21_af90bdcab9/large_this_scene_but_different_0f9d6425_f9b8_422c_bcd5_73076f9a2f21_af90bdcab9.mp4",
        name: "ControlNet Stable Diffusion",
        category: "content",
        slug: "controlnet-stable-diffusion",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1656000933-Screenshot%25202022-06-23%2520171525.png?alt=media&token=912955ed-10bd-4a0e-808e-1d14beb3cb4e",
        name: "How are we going to create this LinkedIn content at scale?",
        category: "content",
        slug: "how-are-we-going-to-create-this-linkedin-content-at-scale",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_long_form_ai_content_generation_63809f2c_e1fd_4f96_b6f2_81caf56a04cb_3174023bc0/large_long_form_ai_content_generation_63809f2c_e1fd_4f96_b6f2_81caf56a04cb_3174023bc0.mp4",
        name: "Generate an entire ebook with AI",
        category: "content",
        slug: "generate-an-entire-ebook-with-ai",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_20230216_161150_41af537fa5/large_Screenshot_20230216_161150_41af537fa5.png",
        name: "From what happened? to what if?",
        category: "attribution",
        slug: "from-what-happened-to-what-if",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2Fadrian-smith-qyEsmQ_K9RM-unsplash.jpg-1637959288?alt=media&token=d78cbd68-5d36-42a1-87bf-c5f3283c193f",
        name: "Do Facebook Ads drive organic?",
        category: "attribution",
        slug: "do-facebook-ads-drive-organic",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_rockerbox_google_sheets_data_sync_png_d850bce803/small_rockerbox_google_sheets_data_sync_png_d850bce803.png",
        name: "Compare our attribution to MMM",
        category: "attribution",
        slug: "compare-our-attribution-to-mmm",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1650533876-amazon-style-recommendations.jpeg?alt=media&token=0313da19-0006-48aa-b6d3-df4b303d439b",
        name: "How can we create Amazon style recommendations?",
        category: "retention",
        slug: "how-can-we-create-amazon-style-recommendations",
      }, //
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1656597886-Screenshot%25202022-06-30%2520150436.png?alt=media&token=74205c8e-1394-47a8-95c2-2c7bf5fc749e",
        name: "How do we build authority",
        category: "content",
        slug: "how-do-we-build-authority",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/Screenshot%202022-09-27%20at%2022.32.06.png",
        name: "How do I measure virality?",
        category: "virality",
        slug: "how-do-i-measure-virality",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1656598750-Screenshot%25202022-06-30%2520151902.png?alt=media&token=3fd5e498-13ea-4cc3-973b-498f4c297a9c",
        name: "How will we find good writers?",
        category: "content",
        slug: "how-will-we-find-good-writers",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_ai_profile_picture_generation_with_dream_booth_081a8471_abe6_4760_a05e_1a676f16e451_b76605bf46/large_ai_profile_picture_generation_with_dream_booth_081a8471_abe6_4760_a05e_1a676f16e451_b76605bf46.mp4",
        name: "Mastering AI Profile Picture Generation: A Deep Dive into Dream Booth",
        category: "content",
        slug: "mastering-ai-profile-picture-generation:-a-deep-dive-into-dream-booth",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_thought_leadership_posts_94efa4b3_3cae_4319_a2da_5f3205b9c521_fb6cf6fa38/large_thought_leadership_posts_94efa4b3_3cae_4319_a2da_5f3205b9c521_fb6cf6fa38.mp4",
        name: "I just started consulting... now how do I get clients?",
        category: "content",
        slug: "i-just-started-consulting...-now-how-do-i-get-clients",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_20230216_134406_e0de45f5f6/large_Screenshot_20230216_134406_e0de45f5f6.png",
        name: "How much is a customer worth?",
        category: "retention",
        slug: "how-much-is-a-customer-worth",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_s9_Cpv7e_FQC_61_Xm_Zv_Hx9_S_Picture1_0556d1f62c/large_s9_Cpv7e_FQC_61_Xm_Zv_Hx9_S_Picture1_0556d1f62c.png",
        name: "How do we stay on topic?",
        category: "content",
        slug: "how-do-we-stay-on-topic",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F%241637853712-%7Bimage.name%7D?alt=media&token=a03d07de-32a4-484c-b5e7-c07e78d79afb",
        name: "How much will CPM rise on Black Friday?",
        category: "attribution",
        slug: "how-much-will-cpm-rise-on-black-friday",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1653580354-julia-koblitz-RlOAwXt2fEA-unsplash.jpg?alt=media&token=69443151-35c2-4271-9d43-a948154678e9",
        name: "How many tests can we run?",
        category: "conversion",
        slug: "how-many-tests-can-we-run",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_03_04_103619_01f4e0842e/large_Screenshot_2023_03_04_103619_01f4e0842e.png",
        name: "Our data is all over the place",
        category: "attribution",
        slug: "our-data-is-all-over-the-place",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2Foliver-hale-oTvU7Zmteic-unsplash.jpg-1634249378?alt=media&token=d2d0c348-17cf-4169-b55a-02a8fbf4be5b",
        name: "Is my data in the right format?",
        category: "attribution",
        slug: "is-my-data-in-the-right-format",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1656598317-Screenshot%25202022-06-30%2520151149.png?alt=media&token=9d5e9043-c4d0-41b7-8da3-90c662022be6",
        name: "We just need more links",
        category: "content",
        slug: "we-just-need-more-links",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_ai_profile_pictures_bf24c203_5c98_4399_81fc_73645fb26d41_d9dbb2c239/large_ai_profile_pictures_bf24c203_5c98_4399_81fc_73645fb26d41_d9dbb2c239.mp4",
        name: "I want an AI profile pic for Twitter",
        category: "content",
        slug: "i-want-an-ai-profile-pic-for-twitter",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_04_04_121042_51314785d9/large_Screenshot_2023_04_04_121042_51314785d9.png",
        name: "How does our agency make money?",
        category: "attribution",
        slug: "how-does-our-agency-make-money",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_03_04_095215_fe9ea6886c/large_Screenshot_2023_03_04_095215_fe9ea6886c.png",
        name: "Is this a real problem, or just an anomaly?",
        category: "attribution",
        slug: "is-this-a-real-problem-or-just-an-anomaly",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1650643016-isaac-smith-6EnTPvPPL6I-unsplash.jpg?alt=media&token=c915e3f7-9411-4a4b-b350-856077530db1",
        name: "Make this chart look better",
        category: "advertising",
        slug: "make-this-chart-look-better",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/thumbnail_Screenshot_2022_11_22_222637_6c3b4d81de/thumbnail_Screenshot_2022_11_22_222637_6c3b4d81de.png",
        name: "What's the long term effect on brand?",
        category: "attribution",
        slug: "what's-the-long-term-effect-on-brand",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1643319275-sacha-t-sas-mVPkezDvRy8-unsplash.jpg?alt=media&token=2e92c17f-d03e-475a-b58f-e5d42785d4ed",
        name: "We need to optimize to leads",
        category: "advertising",
        slug: "we-need-to-optimize-to-leads",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/GoolyBib%20Campaign%20Report.png",
        name: "We need to avoid cannibalizing our audiences",
        category: "advertising",
        slug: "we-need-to-avoid-cannibalizing-our-audiences",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/Ngram%20performance.png",
        name: "What words work best in my ads?",
        category: "advertising",
        slug: "what-words-work-best-in-my-ads",
      }, //////////////
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1656004837-Screenshot%25202022-06-23%2520182030.png?alt=media&token=fda921c4-c11f-4edb-aa9d-c9c338086149",
        name: "What's our 'aha' moment?",
        category: "retention",
        slug: "whats-our-aha-moment",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1655495557-Screenshot%25202022-06-17%2520205230.png?alt=media&token=1962caca-9d35-42af-b01b-b2ad4f2d128e",
        name: "What will people pay for?",
        category: "conversion",
        slug: "what-will-people-pay-for",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/googlybib%20transactions.png",
        name: "Who are our best customers?",
        category: "retention",
        slug: "who-are-our-best-customers",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_05_09_152516_d507a20ff1/large_Screenshot_2023_05_09_152516_d507a20ff1.png",
        name: "We need a tracking plan",
        category: "attribution",
        slug: "we-need-a-tracking-plan",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Old_Website_eb7b3c99f8/large_Old_Website_eb7b3c99f8.png",
        name: "What should we write on the landing page?",
        category: "conversion",
        slug: "what-should-we-write-on-the-landing-page",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_1650976773_brian_mcgowan_ncgf2yp_EF_Bw_unsplash_99de59dd38/large_1650976773_brian_mcgowan_ncgf2yp_EF_Bw_unsplash_99de59dd38.jpg",
        name: "We need to go viral",
        category: "virality",
        slug: "we-need-to-go-viral",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/large_Screenshot_2023_05_09_151243_baf8b16f23/large_Screenshot_2023_05_09_151243_baf8b16f23.png",
        name: "What are people willing to pay?",
        category: "conversion",
        slug: "what-are-people-willing-to-pay",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1649454981-ashley-west-edwards-AjcVTjCz310-unsplash.jpg?alt=media&token=22f95113-4a54-4744-927d-623b6f22b0da",
        name: "What can we post on social media?",
        category: "content",
        slug: "what-can-we-post-on-social-media",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/small_Screenshot_2022_10_27_131335_e6fb9d804d/small_Screenshot_2022_10_27_131335_e6fb9d804d.png",
        name: "What price maximizes profit?",
        category: "conversion",
        slug: "what-price-maximizes-profit",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1656599159-Screenshot%25202022-06-30%2520152514.png?alt=media&token=4d0e7f34-3be0-46fd-b850-44d8cef31b97",
        name: "What topics should we write about?",
        category: "content",
        slug: "what-topics-should-we-write-about",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1643367931-jo-san-diego-ZO_tXe8Tinw-unsplash.jpg?alt=media&token=6a856c01-07c7-4c28-b160-2d96a0f8262f",
        name: "What should we prioritize testing?",
        category: "advertising",
        slug: "what-should-we-prioritize-testing",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2F1662131555-unknown.png?alt=media&token=451aa1a5-06b1-427a-8d08-185d15b455f1",
        name: "What's the source of this lead?",
        category: "attribution",
        slug: "whats-the-source-of-this-lead",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_lm_studio_fcd31406_f231_4cb0_b49c_d4b50a21bb00_50625a91c6/medium_lm_studio_fcd31406_f231_4cb0_b49c_d4b50a21bb00_50625a91c6.mp4",
        name: "I don't want to share my data with OpenAI",
        category: "content",
        slug: "i-don't-want-to-share-my-data-with-openai",
      },
      {
        image:
          "https://storage.googleapis.com/strapi_cms_assets/medium_automatic_prompt_engineer_3493454c_341d_44b0_ae76_8d4a3979c67c_a5286363d1/medium_automatic_prompt_engineer_3493454c_341d_44b0_ae76_8d4a3979c67c_a5286363d1.mp4",
        name: "My employees aren't good at prompting",
        category: "content",
        slug: "my-employees-aren't-good-at-prompting",
      },
    ],
  },
];
@Injectable({
  providedIn: "root",
})
export class StaticDataService {
  affiliateNetworks: AffiliateNetwork[] = [];
  countries: Country[] = [];
  languages: Language[] = [];
  durations: Duration[] = [];
  softwares: Software[] = [];
  offers: AffiliateOffer[] = [];
  paymentPlans!: GetPaymentPlans;
  paymentPlansSubject = new ReplaySubject<GetPaymentPlans>(1);
  paymentPlans$ = this.paymentPlansSubject.asObservable().pipe(shareReplay(1));

  categoriesMap = new Map<number, Category>();

  // Convert to Observable pattern
  public countriesMap = new Map<number, Country>();
  public countriesMapSubject = new ReplaySubject<Map<number, Country>>(1);
  public countriesMap$ = this.countriesMapSubject
    .asObservable()
    .pipe(shareReplay(1));

  softwaresMap = new Map<number, Software>();
  affiliateNetworksMap = new Map<number, string>();
  offersMap = new Map<number, string>();
  languagesMap = new Map<string, string>();
  durationsMap = new Map<string, string>();

  constructor(
    private apiService: ApiService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public stateService: StateService
  ) {
    this.InitializeStartup();
  }

  // Optional: Add getter for synchronous access if needed
  get countriesMapValue(): Map<number, Country> {
    return this.countriesMap;
  }

  public InitializeStartup() {
    this.RegisterMatIcons();
    this.LoadStaticCategories();
    this.LoadStaticCountries();
    this.LoadStaticAffiliateNetworks();
    this.LoadStaticLanguages();
    this.LoadStaticDurations();
    this.LoadStaticSoftwares();
    this.LoadStaticSubscriptionPlans();
    this.LoadStaticOffers();
    this.SubscribeToUser();
  }

  private SubscribeToUser() {
    this.stateService.user$.subscribe((u) => {
      // this.LoadStaticOffers();
    });
  }

  private async LoadStaticCategories() {
    const catData = await this.apiService.GetAllCategories();
    if (catData?.data) {
      catData.data.categories.forEach((cat: Category) => {
        this.categoriesMap.set(cat.categoryId, cat);
      });
      this.categoriesMap.set(0, this.AddDefaultCategory());
    }
  }
  private async LoadStaticCountries() {
    const countryData = await this.apiService.GetAllCountries();
    this.countries = countryData?.data.countries;
    if (countryData?.data) {
      countryData.data.countries.forEach((country: Country) => {
        this.countriesMap.set(country.countryId, country);
      });
      this.countriesMap.set(0, this.AddDefaultCountry());

      // Emit the updated map to all subscribers
      this.countriesMapSubject.next(this.countriesMap);
    }
  }

  private async LoadStaticSubscriptionPlans() {
    const result = await this.apiService.GetAvailablePlans();
    if (result?.data) {
      this.paymentPlans = result.data;
      this.paymentPlansSubject.next(result?.data);
    }
    // this.paymentPlans = {
    //   monthly: {
    //     planPriceId: "pri_01jf7jka95779ydymck95g3zgw",
    //     name: "Monthly",
    //     price: 67,
    //     isTrial: false,
    //   },
    //   monthly24HTrial: {
    //     planPriceId: "pri_01jf7jgpbk0htnvyerzpzessdx",
    //     name: "Monthly 24 Hour Trial",
    //     price: 67,
    //     isTrial: true,
    //   },
    //   yearly: {
    //     planPriceId: "pri_01jf83jcs8rmke8rj1ysz0q93m",
    //     name: "Yearly",
    //     price: 647,
    //     isTrial: false,
    //   },
    //   monthly7DTrial: {
    //     planPriceId: "",
    //     name: "Monthly 7 Day Trial",
    //     price: 67,
    //     isTrial: true,
    //   },
    // };
    // this.paymentPlansSubject.next(this.paymentPlans);
  }

  private async LoadStaticOffers() {
    const offers = await this.apiService.GetAllOffers();
    if (offers?.data?.results) {
      this.offers = offers.data.results;
      this.offers.forEach((offer: AffiliateOffer) => {
        this.offersMap.set(offer.affiliateOfferId, offer.networkOfferId);
      });
      this.offersMap.set(0, "All offers");
    }
  }

  private LoadStaticDurations() {
    this.durations = [
      { durationId: "", displayValue: "All durations" },
      { durationId: "0-30", displayValue: "0:00 - 0:30" },
      { durationId: "30-60", displayValue: "0:30 - 1:00" },
      { durationId: "60-120", displayValue: "1:00 - 2:00" },
      { durationId: "120-300", displayValue: "2:00 - 5:00" },
      { durationId: "300-600", displayValue: "5:00 - 10:00" },
      { durationId: "600-1200", displayValue: "10:00 - 20:00" },
      { durationId: "1200-1800", displayValue: "20:00 - 30:00" },
      { durationId: "1800-2400", displayValue: "30:00 - 40:00" },
      { durationId: "2400-", displayValue: "40:00 +" },
    ];
    this.durations.forEach((duration) => {
      this.durationsMap.set(duration.durationId, duration.displayValue);
    });
  }
  private LoadStaticAffiliateNetworks() {
    this.affiliateNetworks = [
      {
        affiliateNetworkId: 0,
        name: "All networks",
      },
      {
        affiliateNetworkId: 313,
        name: "ClickBank",
      },
      {
        affiliateNetworkId: 329,
        name: "Digistore24",
      },
      {
        affiliateNetworkId: 1034,
        name: "BuyGoods",
      },
      {
        affiliateNetworkId: 1036,
        name: "Giddyup",
      },
      {
        affiliateNetworkId: 1035,
        name: "Squarendance",
      },
      {
        affiliateNetworkId: 1037,
        name: "Warriorplus",
      },
      // {
      //   affiliateNetworkId: 372,
      //   name: "JVZoo",
      // },
    ];
    this.affiliateNetworks.forEach((network: AffiliateNetwork) => {
      this.affiliateNetworksMap.set(network.affiliateNetworkId, network.name);
    });
  }
  private LoadStaticLanguages() {
    this.languages = [
      { code: "", name: "All languages", nativeName: "" },
      { code: "ab", name: "Abkhaz", nativeName: "аҧсуа" },
      { code: "aa", name: "Afar", nativeName: "Afaraf" },
      { code: "af", name: "Afrikaans", nativeName: "Afrikaans" },
      { code: "ak", name: "Akan", nativeName: "Akan" },
      { code: "sq", name: "Albanian", nativeName: "Shqip" },
      { code: "am", name: "Amharic", nativeName: "አማርኛ" },
      { code: "ar", name: "Arabic", nativeName: "العربية" },
      { code: "an", name: "Aragonese", nativeName: "Aragonés" },
      { code: "hy", name: "Armenian", nativeName: "Հայերեն" },
      { code: "as", name: "Assamese", nativeName: "অসমীয়া" },
      { code: "av", name: "Avaric", nativeName: "авар мацӀ, магӀарул мацӀ" },
      { code: "ae", name: "Avestan", nativeName: "avesta" },
      { code: "ay", name: "Aymara", nativeName: "aymar aru" },
      { code: "az", name: "Azerbaijani", nativeName: "azərbaycan dili" },
      { code: "bm", name: "Bambara", nativeName: "bamanankan" },
      { code: "ba", name: "Bashkir", nativeName: "башҡорт теле" },
      { code: "eu", name: "Basque", nativeName: "euskara, euskera" },
      { code: "be", name: "Belarusian", nativeName: "Беларуская" },
      { code: "bn", name: "Bengali", nativeName: "বাংলা" },
      { code: "bh", name: "Bihari", nativeName: "भोजपुरी" },
      { code: "bi", name: "Bislama", nativeName: "Bislama" },
      { code: "bs", name: "Bosnian", nativeName: "bosanski jezik" },
      { code: "br", name: "Breton", nativeName: "brezhoneg" },
      { code: "bg", name: "Bulgarian", nativeName: "български език" },
      { code: "my", name: "Burmese", nativeName: "ဗမာစာ" },
      { code: "ca", name: "Catalan; Valencian", nativeName: "Català" },
      { code: "ch", name: "Chamorro", nativeName: "Chamoru" },
      { code: "ce", name: "Chechen", nativeName: "нохчийн мотт" },
      {
        code: "ny",
        name: "Chichewa; Chewa; Nyanja",
        nativeName: "chiCheŵa, chinyanja",
      },
      {
        code: "zh",
        name: "Chinese",
        nativeName: "中文 (Zhōngwén), 汉语, 漢語",
      },
      { code: "cv", name: "Chuvash", nativeName: "чӑваш чӗлхи" },
      { code: "kw", name: "Cornish", nativeName: "Kernewek" },
      { code: "co", name: "Corsican", nativeName: "corsu, lingua corsa" },
      { code: "cr", name: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ" },
      { code: "hr", name: "Croatian", nativeName: "hrvatski" },
      { code: "cs", name: "Czech", nativeName: "česky, čeština" },
      { code: "da", name: "Danish", nativeName: "dansk" },
      { code: "dv", name: "Divehi; Dhivehi; Maldivian;", nativeName: "ދިވެހި" },
      { code: "nl", name: "Dutch", nativeName: "Nederlands, Vlaams" },
      { code: "en", name: "English", nativeName: "English" },
      { code: "eo", name: "Esperanto", nativeName: "Esperanto" },
      { code: "et", name: "Estonian", nativeName: "eesti, eesti keel" },
      { code: "ee", name: "Ewe", nativeName: "Eʋegbe" },
      { code: "fo", name: "Faroese", nativeName: "føroyskt" },
      { code: "fj", name: "Fijian", nativeName: "vosa Vakaviti" },
      { code: "fi", name: "Finnish", nativeName: "suomi, suomen kieli" },
      { code: "fr", name: "French", nativeName: "français, langue française" },
      {
        code: "ff",
        name: "Fula; Fulah; Pulaar; Pular",
        nativeName: "Fulfulde, Pulaar, Pular",
      },
      { code: "gl", name: "Galician", nativeName: "Galego" },
      { code: "ka", name: "Georgian", nativeName: "ქართული" },
      { code: "de", name: "German", nativeName: "Deutsch" },
      { code: "el", name: "Greek, Modern", nativeName: "Ελληνικά" },
      { code: "gn", name: "Guaraní", nativeName: "Avañeẽ" },
      { code: "gu", name: "Gujarati", nativeName: "ગુજરાતી" },
      {
        code: "ht",
        name: "Haitian; Haitian Creole",
        nativeName: "Kreyòl ayisyen",
      },
      { code: "ha", name: "Hausa", nativeName: "Hausa, هَوُسَ" },
      { code: "he", name: "Hebrew (modern)", nativeName: "עברית" },
      { code: "hz", name: "Herero", nativeName: "Otjiherero" },
      { code: "hi", name: "Hindi", nativeName: "हिन्दी, हिंदी" },
      { code: "ho", name: "Hiri Motu", nativeName: "Hiri Motu" },
      { code: "hu", name: "Hungarian", nativeName: "Magyar" },
      { code: "ia", name: "Interlingua", nativeName: "Interlingua" },
      { code: "id", name: "Indonesian", nativeName: "Bahasa Indonesia" },
      {
        code: "ie",
        name: "Interlingue",
        nativeName: "Originally called Occidental; then Interlingue after WWII",
      },
      { code: "ga", name: "Irish", nativeName: "Gaeilge" },
      { code: "ig", name: "Igbo", nativeName: "Asụsụ Igbo" },
      { code: "ik", name: "Inupiaq", nativeName: "Iñupiaq, Iñupiatun" },
      { code: "io", name: "Ido", nativeName: "Ido" },
      { code: "is", name: "Icelandic", nativeName: "Íslenska" },
      { code: "it", name: "Italian", nativeName: "Italiano" },
      { code: "iu", name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ" },
      {
        code: "ja",
        name: "Japanese",
        nativeName: "日本語 (にほんご／にっぽんご)",
      },
      { code: "jv", name: "Javanese", nativeName: "basa Jawa" },
      {
        code: "kl",
        name: "Kalaallisut, Greenlandic",
        nativeName: "kalaallisut, kalaallit oqaasii",
      },
      { code: "kn", name: "Kannada", nativeName: "ಕನ್ನಡ" },
      { code: "kr", name: "Kanuri", nativeName: "Kanuri" },
      { code: "ks", name: "Kashmiri", nativeName: "कश्मीरी, كشميري‎" },
      { code: "kk", name: "Kazakh", nativeName: "Қазақ тілі" },
      { code: "km", name: "Khmer", nativeName: "ភាសាខ្មែរ" },
      { code: "ki", name: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ" },
      { code: "rw", name: "Kinyarwanda", nativeName: "Ikinyarwanda" },
      { code: "ky", name: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили" },
      { code: "kv", name: "Komi", nativeName: "коми кыв" },
      { code: "kg", name: "Kongo", nativeName: "KiKongo" },
      {
        code: "ko",
        name: "Korean",
        nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
      },
      { code: "ku", name: "Kurdish", nativeName: "Kurdî, كوردی‎" },
      { code: "kj", name: "Kwanyama, Kuanyama", nativeName: "Kuanyama" },
      { code: "la", name: "Latin", nativeName: "latine, lingua latina" },
      {
        code: "lb",
        name: "Luxembourgish, Letzeburgesch",
        nativeName: "Lëtzebuergesch",
      },
      { code: "lg", name: "Luganda", nativeName: "Luganda" },
      {
        code: "li",
        name: "Limburgish, Limburgan, Limburger",
        nativeName: "Limburgs",
      },
      { code: "ln", name: "Lingala", nativeName: "Lingála" },
      { code: "lo", name: "Lao", nativeName: "ພາສາລາວ" },
      { code: "lt", name: "Lithuanian", nativeName: "lietuvių kalba" },
      { code: "lu", name: "Luba-Katanga", nativeName: "" },
      { code: "lv", name: "Latvian", nativeName: "latviešu valoda" },
      { code: "gv", name: "Manx", nativeName: "Gaelg, Gailck" },
      { code: "mk", name: "Macedonian", nativeName: "македонски јазик" },
      { code: "mg", name: "Malagasy", nativeName: "Malagasy fiteny" },
      { code: "ms", name: "Malay", nativeName: "bahasa Melayu, بهاس ملايو‎" },
      { code: "ml", name: "Malayalam", nativeName: "മലയാളം" },
      { code: "mt", name: "Maltese", nativeName: "Malti" },
      { code: "mi", name: "Māori", nativeName: "te reo Māori" },
      { code: "mr", name: "Marathi (Marāṭhī)", nativeName: "मराठी" },
      { code: "mh", name: "Marshallese", nativeName: "Kajin M̧ajeļ" },
      { code: "mn", name: "Mongolian", nativeName: "монгол" },
      { code: "na", name: "Nauru", nativeName: "Ekakairũ Naoero" },
      {
        code: "nv",
        name: "Navajo, Navaho",
        nativeName: "Diné bizaad, Dinékʼehǰí",
      },
      { code: "nb", name: "Norwegian Bokmål", nativeName: "Norsk bokmål" },
      { code: "nd", name: "North Ndebele", nativeName: "isiNdebele" },
      { code: "ne", name: "Nepali", nativeName: "नेपाली" },
      { code: "ng", name: "Ndonga", nativeName: "Owambo" },
      { code: "nn", name: "Norwegian Nynorsk", nativeName: "Norsk nynorsk" },
      { code: "no", name: "Norwegian", nativeName: "Norsk" },
      { code: "ii", name: "Nuosu", nativeName: "ꆈꌠ꒿ Nuosuhxop" },
      { code: "nr", name: "South Ndebele", nativeName: "isiNdebele" },
      { code: "oc", name: "Occitan", nativeName: "Occitan" },
      { code: "oj", name: "Ojibwe, Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
      {
        code: "cu",
        name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
        nativeName: "ѩзыкъ словѣньскъ",
      },
      { code: "om", name: "Oromo", nativeName: "Afaan Oromoo" },
      { code: "or", name: "Oriya", nativeName: "ଓଡ଼ିଆ" },
      { code: "os", name: "Ossetian, Ossetic", nativeName: "ирон æвзаг" },
      { code: "pa", name: "Panjabi, Punjabi", nativeName: "ਪੰਜਾਬੀ, پنجابی‎" },
      { code: "pi", name: "Pāli", nativeName: "पाऴि" },
      { code: "fa", name: "Persian", nativeName: "فارسی" },
      { code: "pl", name: "Polish", nativeName: "polski" },
      { code: "ps", name: "Pashto, Pushto", nativeName: "پښتو" },
      { code: "pt", name: "Portuguese", nativeName: "Português" },
      { code: "qu", name: "Quechua", nativeName: "Runa Simi, Kichwa" },
      { code: "rm", name: "Romansh", nativeName: "rumantsch grischun" },
      { code: "rn", name: "Kirundi", nativeName: "kiRundi" },
      {
        code: "ro",
        name: "Romanian, Moldavian, Moldovan",
        nativeName: "română",
      },
      { code: "ru", name: "Russian", nativeName: "русский язык" },
      { code: "sa", name: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्" },
      { code: "sc", name: "Sardinian", nativeName: "sardu" },
      { code: "sd", name: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎" },
      { code: "se", name: "Northern Sami", nativeName: "Davvisámegiella" },
      { code: "sm", name: "Samoan", nativeName: "gagana faa Samoa" },
      { code: "sg", name: "Sango", nativeName: "yângâ tî sängö" },
      { code: "sr", name: "Serbian", nativeName: "српски језик" },
      { code: "gd", name: "Scottish Gaelic; Gaelic", nativeName: "Gàidhlig" },
      { code: "sn", name: "Shona", nativeName: "chiShona" },
      { code: "si", name: "Sinhala, Sinhalese", nativeName: "සිංහල" },
      { code: "sk", name: "Slovak", nativeName: "slovenčina" },
      { code: "sl", name: "Slovene", nativeName: "slovenščina" },
      { code: "so", name: "Somali", nativeName: "Soomaaliga, af Soomaali" },
      { code: "st", name: "Southern Sotho", nativeName: "Sesotho" },
      {
        code: "es",
        name: "Spanish; Castilian",
        nativeName: "español, castellano",
      },
      { code: "su", name: "Sundanese", nativeName: "Basa Sunda" },
      { code: "sw", name: "Swahili", nativeName: "Kiswahili" },
      { code: "ss", name: "Swati", nativeName: "SiSwati" },
      { code: "sv", name: "Swedish", nativeName: "svenska" },
      { code: "ta", name: "Tamil", nativeName: "தமிழ்" },
      { code: "te", name: "Telugu", nativeName: "తెలుగు" },
      { code: "tg", name: "Tajik", nativeName: "тоҷикӣ, toğikī, تاجیکی‎" },
      { code: "th", name: "Thai", nativeName: "ไทย" },
      { code: "ti", name: "Tigrinya", nativeName: "ትግርኛ" },
      {
        code: "bo",
        name: "Tibetan Standard, Tibetan, Central",
        nativeName: "བོད་ཡིག",
      },
      { code: "tk", name: "Turkmen", nativeName: "Türkmen, Түркмен" },
      {
        code: "tl",
        name: "Tagalog",
        nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
      },
      { code: "tn", name: "Tswana", nativeName: "Setswana" },
      { code: "to", name: "Tonga (Tonga Islands)", nativeName: "faka Tonga" },
      { code: "tr", name: "Turkish", nativeName: "Türkçe" },
      { code: "ts", name: "Tsonga", nativeName: "Xitsonga" },
      { code: "tt", name: "Tatar", nativeName: "татарча, tatarça, تاتارچا‎" },
      { code: "tw", name: "Twi", nativeName: "Twi" },
      { code: "ty", name: "Tahitian", nativeName: "Reo Tahiti" },
      { code: "ug", name: "Uighur, Uyghur", nativeName: "Uyƣurqə, ئۇيغۇرچە‎" },
      { code: "uk", name: "Ukrainian", nativeName: "українська" },
      { code: "ur", name: "Urdu", nativeName: "اردو" },
      { code: "uz", name: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎" },
      { code: "ve", name: "Venda", nativeName: "Tshivenḓa" },
      { code: "vi", name: "Vietnamese", nativeName: "Tiếng Việt" },
      { code: "vo", name: "Volapük", nativeName: "Volapük" },
      { code: "wa", name: "Walloon", nativeName: "Walon" },
      { code: "cy", name: "Welsh", nativeName: "Cymraeg" },
      { code: "wo", name: "Wolof", nativeName: "Wollof" },
      { code: "fy", name: "Western Frisian", nativeName: "Frysk" },
      { code: "xh", name: "Xhosa", nativeName: "isiXhosa" },
      { code: "yi", name: "Yiddish", nativeName: "ייִדיש" },
      { code: "yo", name: "Yoruba", nativeName: "Yorùbá" },
      {
        code: "za",
        name: "Zhuang, Chuang",
        nativeName: "Saɯ cueŋƅ, Saw cuengh",
      },
    ];
    this.languages.forEach((language) => {
      this.languagesMap.set(language.code, language.name);
    });
  }
  private LoadStaticSoftwares() {
    this.softwares = [
      {
        name: "Webinar Funnels",
        softwareId: 999990,
        children: [
          { softwareId: 1031, name: "EasyWebinar" },
          { softwareId: 1030, name: "WebinarJam" },
        ],
      },
      {
        name: "Conversion and Sales Funnel Optimization",
        softwareId: 999991,
        children: [
          { softwareId: 1027, name: "ClickFunnels" },
          { softwareId: 1033, name: "Unbounce" },
          { softwareId: 1040, name: "DeadlineFunnel" },
          { softwareId: 1049, name: "VWO" },
          { softwareId: 1028, name: "Funnelish" },
        ],
      },
      {
        name: "Video Marketing and Analytics",
        softwareId: 999992,
        children: [
          { softwareId: 1043, name: "Vidalytics" },
          { softwareId: 1050, name: "Wistia" },
        ],
      },
      {
        name: "Marketing Attribution and Analytics",
        softwareId: 999993,
        children: [
          { softwareId: 1038, name: "Hyros" },
          { softwareId: 1041, name: "Wicked Reports" },
          { softwareId: 1042, name: "Northbeam" },
          { softwareId: 1047, name: "TripleWhale" },
          { softwareId: 1039, name: "Funnelytics" },
        ],
      },
      {
        name: "Lead Optimization",
        softwareId: 999994,
        children: [{ softwareId: 1044, name: "Active Prospect" }],
      },
      {
        name: "Conversion Tracking",
        softwareId: 999995,
        children: [{ softwareId: 1048, name: "Elevar" }],
      },
      {
        name: "Call Tracking and Management",
        softwareId: 999996,
        children: [{ softwareId: 1032, name: "Ringba" }],
      },
      {
        name: "Marketing Automation and CRM",
        softwareId: 999997,
        children: [{ softwareId: 1046, name: "Ontraport" }],
      },
    ];

    this.CreateSoftwareMaps(this.softwares);

    // this.softwares = [
    //   // { softwareId: 1032, name: "Ringba" },
    //   { softwareId: 1044, name: "Active Prospect" },
    //   // { softwareId: 1049, name: "VWO" },
    //   // { softwareId: 1031, name: "EasyWebinar" },
    //   // { softwareId: 1030, name: "WebinarJam" },
    //   // { softwareId: 1038, name: "Hyros" },
    //   // { softwareId: 1041, name: "Wicked Reports" },
    //   // { softwareId: 1042, name: "Northbeam" },
    //   // { softwareId: 1048, name: "Elevar" },
    //   // { softwareId: 1047, name: "TripleWhale" },
    //   // { softwareId: 1039, name: "Funnelytics" },
    //   // { softwareId: 1043, name: "Vidalytics" },
    //   // { softwareId: 1050, name: "Wistia" },
    //   //  { softwareId: 1028, name: "Funnelish" },
    //   //{ softwareId: 1040, name: "DeadlineFunnel" },
    //   // { softwareId: 1046, name: "Ontraport" },
    //   // { softwareId: 1027, name: "ClickFunnels" },
    //   // { softwareId: 1033, name: "Unbounce" },
    //   { softwareId: 1060, name: "Leadshook" },
    // ];

    // this.softwares.forEach((software) => {
    //   this.softwaresMap.set(software.softwareId, software);
    // });
  }
  public AddDefaultCategory(): Category {
    return {
      name: "All categories",
      categoryId: 0,
      children: [],
    };
  }
  public AddDefaultCountry(): Country {
    return {
      name: "Worldwide",
      alpha2Code: "ALL",
      code: "0",
      count: 0,
      countryId: 0,
    };
  }
  public AddDefaultNetwork(): AffiliateNetwork {
    return {
      name: "All networks",
      affiliateNetworkId: 0,
    };
  }
  public get Countries() {
    return this.countries;
  }
  private RegisterMatIcons() {
    this.matIconRegistry.addSvgIcon(
      "company-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/company-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "bs-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/bs-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "brand-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/brand-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "affiliate-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/affiliate-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "affiliate-menu-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/affiliate-menu-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "affiliate-menu-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/affiliate-menu-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "see-more-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/see-more-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "money-back-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/money-back-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "marketer-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/marketer-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "categories-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/categories-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "x-close",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/x-close.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-1",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-1.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-2",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-2.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-3",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-3.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-4",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-4.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-5",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-5.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-6",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-6.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-7",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-7.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-8",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-8.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-9",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-9.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-10",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-10.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-11",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-11.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-12",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-12.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-13",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-13.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-14",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-14.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-15",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-15.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-16",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-16.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-17",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-17.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-18",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-18.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "check-circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/check-circle.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "menu-19",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/menu-19.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-up-down",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/arrow-up-down.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-up-black",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/arrow-up-black.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-down-black",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/arrow-down-black.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "share-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/share-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "share2-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/share2-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "edit-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/edit-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "delete-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/delete-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-left",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/arrow-left.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-right",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/arrow-right.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "worldwide",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/worldwide.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "networks-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/networks-icon.svg"
      )
    );
  }
  public GetCategorizedTrainings() {
    return categorizedTraingings;
  }

  public CreateSoftwareMaps(softwares: Software[]) {
    for (const software of softwares) {
      this.softwaresMap.set(software.softwareId, software);

      if (software.children) this.CreateSoftwareMaps(software.children);
    }
  }
}
