import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { StateService } from "src/app/services/shared/state.service";
// import { PaddleService } from "src/app/services/paddle/paddle.service";
import { map } from "rxjs/operators";
import { ModuleType } from "src/app/models/enums";
import { PopupService } from "src/app/services/shared/popup.service";
import { ApiService } from "src/app/services/api/api.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { environment } from "src/environments/environment";
import { StaticDataService } from "src/app/services/shared/static.data.service";
import { PaymentPlan } from "src/app/models/paymentPlan";

@Component({
  selector: "app-in-app-navbar",
  templateUrl: "./in-app-navbar.component.html",
  styleUrls: ["./in-app-navbar.component.scss"],
})
export class InAppNavbarComponent implements OnInit {
  currentUser$!: Observable<any>;
  isPayingUser: boolean = false;
  IsTrialAlreadyUsed: boolean = false;
  isTrialUser: boolean = false;
  isPastDue: boolean = false;
  expiryDate: Date | undefined = new Date();
  moduleType = ModuleType;
  paymentPlan!: PaymentPlan;
  @Output() sidebarToggled: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    public stateService: StateService,
    public staticDataService: StaticDataService,
    public apiService: ApiService,
    // public paddleService: PaddleService,
    public popupService: PopupService
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.stateService.user$;
    this.SubscribeToUser();
  }

  public OpenBlackFridayPage() {
    window.location.href = "https://deal.vidtao.com";
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe(async (user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
      this.IsTrialAlreadyUsed = this.stateService.IsTrialAlreadyUsed(user);
      this.isTrialUser = this.stateService.IsTrialUser(user);
      this.expiryDate = this.stateService.GetExpiryDate(user);
      this.isPastDue = user?.pastDue ?? false;
      if (this.isPastDue) await this.LoadCurrentPlan();
    });
  }

  public async BuyTrialSubscription() {
    const userResult = await this.apiService.GetUser();
    if (this.stateService.IsPayingUserState(userResult?.data)) {
      this.popupService.Notify("You have already started your trial.");
      this.stateService.UpdateUser(userResult?.data);
      return;
    }

    if (this.staticDataService.paymentPlans && !this.popupService.modalOpened)
      this.popupService.OpenTrialSubscription();
  }

  public async BuyPremiumSubscription() {
    if (this.staticDataService.paymentPlans && !this.popupService.modalOpened)
      this.popupService.OpenPremiumSubscription();
  }


  public async LoadCurrentPlan() {
    const result = await this.apiService.GetCurrentPlan();

    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);

    if (result?.data) this.paymentPlan = result?.data;
  }

  public UpdateCard() {
    window.open(this.paymentPlan.updateUrl, "_blank");
  }

  public ToggleSidebar() {
    this.sidebarToggled.emit();
  }
}
