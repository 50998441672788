import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-plan-radio',
  templateUrl: './plan-radio.component.html',
  styleUrl: './plan-radio.component.scss'
})
export class PlanRadioComponent implements OnChanges {
  @Output() onSelectPlan: EventEmitter<string> = new EventEmitter<string>();
  @Input() selectedPlan: string = 'monthly';


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedPlan']) {
      this.selectedPlan = changes['selectedPlan'].currentValue;
    }
  }

  public SelectPlan(plan: string) {
    this.selectedPlan = plan;
    this.onSelectPlan.emit(plan);
  }

  
}
