<mat-icon style="font-size: 0px !important; width: 20px !important;" class="ml-2 mt-0 see-more-button"
    (click)="showPopover($event)" [svgIcon]="'see-more-icon'">
    <p-button label="Show Popover" styleClass="min-w-48" *ngIf="false"></p-button>
</mat-icon>
<!-- <i class='bx bx-info-circle ml-2 mt-0 see-more-button' (click)="showPopover($event)">
    <p-button label="Show Popover" styleClass="min-w-48" *ngIf="false"></p-button>
</i> -->
<p-overlayPanel #popover>
    <h6>{{ title }}</h6>
    <p style="max-width: 300px;" class="mb-0">{{ text }}</p>
</p-overlayPanel>