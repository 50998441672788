import { Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { combineLatest, map } from "rxjs";
import { InAppSidebarComponent } from "src/app/components/navigation/in-app-sidebar/in-app-sidebar.component";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";
import { CheckIfUserUsingBlocker } from "src/app/utils/checkForAdBlocker";
import { slideInAnimation } from "src/app/utils/entry-animation";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  animations: [slideInAnimation],
})
export class DashboardComponent implements OnInit {
  @ViewChild(InAppSidebarComponent)
  sidebarComponent!: InAppSidebarComponent;
  showBanner: boolean = false;
  signUpSeqFinished: boolean = false;
  canOpenTutorial: boolean = false;
  userExists: boolean = false;
  constructor(
    public renderer: Renderer2,
    public popupService: PopupService,
    public apiService: ApiService,
    public stateService: StateService,
    public staticDataService: StaticDataService
  ) { }

  ngOnInit(): void {
    this.SubscribeToUser();
    this.CheckAdBlocker();
    if (this.stateService.isFirstLogin) {
      this.stateService.isFirstLogin = false;
      this.popupService.openSignupSequence(() => {
        if (!this.stateService.firebaseUser.emailVerified)
          this.popupService.openVerifyCheck();
        else {
          this.canOpenTutorial = true;
        }
      });
    } else if (!this.stateService.firebaseUser.emailVerified) {
      this.popupService.openVerifyCheck();
    } else if (this.stateService.firebaseUser.emailVerified) {
      this.canOpenTutorial = true;
    }
  }

  public SubscribeToUser() {
    combineLatest([
      this.stateService.user$,
      this.staticDataService.paymentPlans$,
    ])
      .pipe(
        map(async ([user, plans]) => {
          if (user) this.userExists = true;
          if (user && !user?.hasWatchedSearch) this.OpenTutorial();
          if (user) this.showBanner = user?.showStrategySessionBanner;
          if (
            this.stateService.openSubscriptionModal &&
            user?.plan === "free"
          ) {
            if (user?.isFreeTrialUsed)
              this.popupService.OpenPremiumSubscription();
            else
              this.popupService.OpenTrialSubscription();
          }
        })
      )
      .subscribe();
  }

  public async OpenTutorial() {
    while (!this.canOpenTutorial) {
      await this.Sleep(200);
    }
    await this.Sleep(3000);
    this.popupService.OpenSearchTutorial();
  }

  Sleep(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }

  public async RemoveSessionBanner() {
    this.showBanner = false;
    await this.apiService.RemoveStrategySessionBanner();
  }

  public PrepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animation"]
    );
  }

  public CheckAdBlocker() {
    if (CheckIfUserUsingBlocker())
      this.popupService.Notify(
        "For a seamless browsing experience, please deactivate your ad blockers."
      );
  }

  public SidebarToggled() {
    this.sidebarComponent?.ToggleSidebar();
  }
}
