import { firebaseConfig } from "../app/services/firebase/config/firebase-config";
export const environment = {
  domain: "https://v2.vidtao.com/#",
  production: false,
  firebaseConfig: firebaseConfig,
  vendorId: 11542,
  trialPlanId: 65791,
  paddleSandbox: false,
  // clientSidePaddleToken: "test_eac47c86342575026705a1c69bb",
  clientSidePaddleToken: "live_4073bd9aa71cd5de73273a4a2e9",
  paddleApiUrl: "https://api.paddle.com/",
  monthlyPlanPriceId: "pri_01jbxvgxs5qb0edc10gxce8v8s",
  monthlyTrialPlanPriceId: "pri_01j6h9m51m0dcjkkcv00pgg80b",
  yearlyPlanPriceId: "pri_01je3sz8tdbjn1ks59m7jgknwe",
  rudderStackId: "2k6k0cdsLEAWjnrcammZzic2ZPr",
  rudderStackUrl: "https://inceptlyvbmctf.dataplane.rudderstack.com/",
  mapbox: {
    accessToken:
      "pk.eyJ1IjoiZHJhc2tvMTIzIiwiYSI6ImNsbzRhaWd4cTAwMjIybG8yODYydnBuNTYifQ.diWuP1BL6LeEvRyeSNnw3g",
  },

  // apiUrl: "https://dev.api.vidtao.com", //dev - automatski se deploy nakon pusha
  // apiUrl: "http://178.156.141.22:3000",
  apiUrl: "https://lufor5jdx0.execute-api.us-east-2.amazonaws.com/",
  // apiUrl: "https://testapi.vidtao.com",
  // apiUrl: "http://localhost:3000",
};
